import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Autoplay, Pagination } from "swiper";

import slide1 from "../../assets/swiper-slide-text-1.jpeg";
import slide2 from "../../assets/swiper-slide-text-2.jpeg";
import slide3 from "../../assets/swiper-slide-text-3.jpeg";
import slide4 from "../../assets/swiper-slide-text-4.jpeg";
import slide5 from "../../assets/swiper-slide-text-5.jpeg";

export default function SwiperComponent(props) {
  return (
    <>
      <Swiper
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay, Pagination]}
      >
        <SwiperSlide>
          <img src={slide1} alt="Slide" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide2} alt="Slide" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide3} alt="Slide" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide4} alt="Slide" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide5} alt="Slide" />
        </SwiperSlide>
      </Swiper>
    </>
  );
}
