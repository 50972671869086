import React from "react";

import SwiperComponent from "../../components/Swiper/Swiper-component";

export default function HomePage() {
  return (
    <>
      <div style={{ height: "80vh" }}>
        <SwiperComponent />
      </div>
    </>
  );
}
