import React from "react";
import { Link } from "react-router-dom";

import HouseIcon from "../../utils/svg-component/house-icon";
import InstagramIcon from "../../utils/svg-component/instagram-icon";
import FacebookIcon from "../../utils/svg-component/facebook-icon";

import logo from "../../assets/logo.png";

export default function NavBar() {
  return (
    <>
      <nav className="relative flex flex-wrap items-center justify-between px-2 py-3 bg-integra-white-200">
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <span className="text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase text-white">
              <Link to="/">
                <HouseIcon width={24} height={24} fill="#8D50AF" />
              </Link>
            </span>
          </div>

          <div className="lg:flex flex-grow items-center justify-center">
            <ul className="flex flex-col lg:flex-row list-none">
              <li className="nav-item">
                <span className="px-3 py-2 flex item-center text-xs uppercase font-bold leading-snug text-white hover:text-integra-purple cursor-pointer text-integra-text-color">
                  NOSOTROS
                </span>
              </li>
              <li className="nav-item">
                <span className="px-3 py-2 flex item-center text-xs uppercase font-bold leading-snug text-white hover:text-integra-purple cursor-pointer text-integra-text-color">
                  EVENTOS SOCIALES
                </span>
              </li>
            </ul>
          </div>

          <div className="lg:flex flex-grow items-center justify-center">
            <ul className="flex flex-col lg:flex-row list-none">
              <li className="nav-item">
                <img src={logo} alt="logo integra" className="w-80" />
              </li>
            </ul>
          </div>

          <div className="lg:flex flex-grow items-center justify-center">
            <ul className="flex flex-col lg:flex-row list-none">
              <li className="nav-item">
                <span className="px-3 py-2 flex item-center text-xs uppercase font-bold leading-snug text-white hover:text-integra-purple cursor-pointer text-integra-text-color">
                  CONGRESOS/CONVENCIONES
                </span>
              </li>
            </ul>
          </div>

          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <span className="inline-block mr-4 p-2 rounded-full bg-integra-purple">
              <a
                href="https://www.facebook.com/INTEGRAECT"
                target="_blank"
                rel="noreferrer"
              >
                <FacebookIcon width={15} height={15} fill="#fff" />
              </a>
            </span>

            <span className="inline-block mr-4 p-2 rounded-full bg-integra-purple">
              <a
                href="https://www.instagram.com/integra.ect/"
                target="_blank"
                rel="noreferrer"
              >
                <InstagramIcon width={15} height={15} fill="#fff" />
              </a>
            </span>
          </div>
        </div>
      </nav>
    </>
  );
}
